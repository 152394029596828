import React from 'react'
import { Layout } from '../components/layout'
import Meta from '../components/layout/meta'
import { sectors as faqs } from '../faqs'
import { Container, PatternDots, Text as TextBlock, TextContainer } from '../utils'
import StreamlineIcon from '../images/icons/streamline'
import SocialProofBlock from '../components/pages/homepage/socialProofBlock'
import Blob from '../images/pricing/blob'
import Blub from '../images/pricing/blub'
import Blib from '../images/pricing/blib'
import content from '../components/pages/voor-wie/content'
import {
    BackgroundBlock,
    BackgroundElement,
    ButtonRow,
    CardRow,
    ContentBlock,
    CTAButton,
    HeroHeading,
    RelativeContainer,
    SectorCard,
    SectorHeader,
    Shortcuts,
    StyledList,
    StyledTextBlock,
    Ticker,
} from '../components/pages/voor-wie/styled'
import { Accordeon, RegisterButton, Link } from '../components/UI'
import { reviewStructuredData } from '../components/layout/meta/schema-org'

const ForWhomPage = () => (
    <Layout>
        <Meta
            keywords={['zzp', 'boekhouden', 'tellow', 'freelance']}
            title="Voor wie is Tellow?"
            description="Tellow is voor zzp’ers die meer tijd over willen houden voor hun werk. Ontdek of Tellow bij jou past."
            path="/voor-wie/"
            jsonLD={reviewStructuredData}
        />
        <BackgroundBlock>
            <Container>
                <TextBlock style={{ margin: 'rem' }}>
                    <HeroHeading size={40} sizeTablet={35} sizeDesktop={45}>
                        Ontdek of Tellow bij jou past
                    </HeroHeading>
                    <Ticker>
                        Tellow wordt met liefde gebruikt <br />
                        door vele sectoren en freelancers.
                    </Ticker>
                </TextBlock>
                <Shortcuts>
                    <a href="#legal">
                        <StreamlineIcon icon="Click" />
                        <p className="underline orangeline">Rechtsvormen</p>
                    </a>
                    <a href="#sectors">
                        <StreamlineIcon icon="Camera" />
                        <p className="underline blueline">Sectoren</p>
                    </a>
                    <a href="#admintypes">
                        <StreamlineIcon icon="Money" />
                        <p className="underline greenline">Administratietypes</p>
                    </a>
                </Shortcuts>
            </Container>
        </BackgroundBlock>
        <ContentBlock id="legal" className="legalforms">
            <RelativeContainer>
                <StyledTextBlock width="60%">
                    <h2>Kan ik Tellow gebruiken?</h2>
                    <p>Je kunt meteen starten als je onderneming onder een van deze rechtsvormen valt:</p>
                    <StyledList>
                        <li>Eenmanszaak</li>
                        <li>BV t/m 5 man personeel</li>
                    </StyledList>
                    <p>
                        Heb je een stichting, maatschap, coöperatie, BV met meer dan 5 personen, een VOF of gebruik je een kassasysteem? Neem{' '}
                        <strong>
                            <Link to="/support/">contact</Link>
                        </strong>{' '}
                        met ons op.
                    </p>
                    <ButtonRow>
                        <RegisterButton context="for-whom">Probeer Tellow gratis</RegisterButton>
                    </ButtonRow>
                </StyledTextBlock>
                <BackgroundElement className="first">
                    <Blob />
                </BackgroundElement>
                <BackgroundElement className="second">
                    <Blub />
                </BackgroundElement>
            </RelativeContainer>
        </ContentBlock>
        <ContentBlock id="sectors">
            <Container>
                <StyledTextBlock>
                    <h2>Wie maakt gebruik van Tellow?</h2>
                    <p>
                        Van jong tot oud, startend tot zeer ervaren en verdeeld over alle sectoren; veel freelancers vertrouwen al op Tellow. Onder onze gebruikers bevinden zich:
                    </p>
                    <CardRow>
                        {content.map((i) => (
                            <SectorCard key={i.name}>
                                <a href={i.url}>
                                    <SectorHeader src={i.src} alt={i.alt} />
                                    <p>{i.title}</p>
                                </a>
                            </SectorCard>
                        ))}
                    </CardRow>
                    <CTAButton href="https://app.tellow.nl/registreer" target="_blank" center>
                        Probeer Tellow gratis
                    </CTAButton>
                </StyledTextBlock>
            </Container>
        </ContentBlock>
        <ContentBlock id="admintypes">
            <RelativeContainer>
                <StyledTextBlock width="70%">
                    <h2>Voor wie is Tellow geschikt?</h2>
                    <p>Vind jij dat de administratieve kant van ondernemen gemakkelijker kan? Wij ook!</p>
                    <p>
                        Tellow is er voor zzp’ers en kleine ondernemers. In welke sector je ook werkt en hoe oud je ook bent; Tellow is er voor iedereen die meer vrijheid wil
                        ervaren in zijn of haar werkende leven. Ben je zelfstandig en kun je overweg met een computer? Dan is de kans groot dat je met Tellow aan de slag kunt.
                    </p>
                    <br />
                    <h3>Onze all-in-one boekhouding is voor:</h3>
                    <StyledList>
                        <li>Starters met weinig uitgaven</li>
                        <li>Ervaren ondernemers met veel facturen</li>
                        <li>Kleine ondernemers die deelnemen aan de nieuwe KOR</li>
                        <li>Freelancers die willen overstappen vanuit een ander boekhoudpakket</li>
                        <li>Zzp’ers met een boekhouding in Excel</li>
                    </StyledList>
                    <p>
                        Met Tellow heb je een compleet boekhoudpakket waarmee je facturen stuurt, automatisch bonnen en inkoopfacturen verwerkt en altijd overzicht hebt in je
                        financiën. Lees meer over de{' '}
                        <strong>
                            <Link to="/functies/">functies van Tellow</Link>
                        </strong>
                        .
                    </p>
                    <CTAButton href="https://app.tellow.nl/registreer" target="_blank" center>
                        Probeer Tellow gratis
                    </CTAButton>
                </StyledTextBlock>
                <BackgroundElement className="third">
                    <Blib />
                </BackgroundElement>
                <BackgroundElement className="dots">
                    <PatternDots />
                </BackgroundElement>
            </RelativeContainer>
        </ContentBlock>
        <SocialProofBlock />
        <TextContainer style={{ maxWidth: 760, margin: '0 auto' }}>
            <h2>Voor wie is Tellow niet geschikt?</h2>
            {faqs.map((item) => (
                <Accordeon key={item.question} question={item.question}>
                    {item.answer}
                </Accordeon>
            ))}
        </TextContainer>
    </Layout>
)

export default ForWhomPage
