import FitnessTrainer from '../../../images/forwhom/FitnessTrainer.png'
import ITSpecialist from '../../../images/forwhom/ITSpecialist.png'
import Therapeut from '../../../images/forwhom/Therapeut.png'
import Zorg from '../../../images/forwhom/Zorg.png'
import Pakketbezorger from '../../../images/forwhom/Pakketbezorger.png'
import Yoga from '../../../images/forwhom/Yoga.png'
import Interieurstylist from '../../../images/forwhom/Interieurstylist.png'
import Consultant from '../../../images/forwhom/Consultant.png'
import Bouw from '../../../images/forwhom/bouwsector-header.jpg'
import JongeOndernemer from '../../../images/forwhom/jonge-ondernemer.jpg'

const prefix = 'Tellow zzp boekhoudprogramma voor'
const content = [
    {
        name: 'itspecialist',
        plural: 'IT-Specialisten',
        url: '/lp/itspecialist',
        src: ITSpecialist,
        alt: `${prefix} IT-specialisten.`,
        title: 'IT-Specialist',
    },
    {
        name: 'consultant',
        plural: 'consultants',
        url: '/lp/consultants',
        src: Consultant,
        alt: `${prefix} consultants.`,
        title: 'Consultant',
    },
    {
        name: 'bouw',
        plural: 'de bouw',
        url: '/lp/bouw',
        src: Bouw,
        alt: `${prefix} de bouw.`,
        title: 'Bouw',
    },
    {
        name: 'zorg',
        plural: 'de zorg',
        url: '/lp/zorg',
        src: Zorg,
        alt: `${prefix} de zorg.`,
        title: 'Zorg',
    },
    {
        name: 'pakketbezorger',
        plural: 'pakketbezorgers',
        url: '/lp/bezorgers',
        src: Pakketbezorger,
        alt: `${prefix} pakketbezorgers.`,
        title: 'Pakketbezorger',
    },
    {
        name: 'yoga',
        plural: 'yoga docenten',
        url: '/lp/yoga',
        src: Yoga,
        alt: `${prefix} de yoga.`,
        title: 'Yogadocent',
    },
    {
        name: 'sporttrainer',
        plural: 'trainers',
        url: '/lp/sporttrainers',
        src: FitnessTrainer,
        alt: `${prefix} sporttrainers.`,
        title: 'Sport-Fitness Trainer',
    },
    {
        name: 'therapeut',
        plural: 'therapeuten',
        url: '/lp/therapeuten',
        src: Therapeut,
        alt: `${prefix} therapeuten.`,
        title: 'Therapeut',
    },
    {
        name: 'stylist',
        plural: 'stylisten',
        url: '/lp/stylisten',
        src: Interieurstylist,
        alt: `${prefix} stylisten.`,
        title: 'Interieurstylist',
    },
    {
        name: 'jongeondernemer',
        plural: 'jonge ondernemers',
        url: '/lp/jonge-ondernemers',
        src: JongeOndernemer,
        alt: `${prefix} jonge ondernemers.`,
        title: 'Jonge Ondernemers',
    },
]

export default content
