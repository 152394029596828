import React from 'react'

const Blob = () => (
    <svg width="100%" height="100%" viewBox="0 0 283 325" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M251.333 85.207c24.668 40.578 35.375 82.454 30.528 127.79-4.774 45.335-25.175 94.13-59.176 107.753-34 13.622-81.602-7.785-122.113-31.858-40.44-24.073-73.789-50.813-89.921-90.238-16.133-39.498-15.047-91.752 10.923-132.62C47.545 25.169 98.402-4.31 143.181.519c44.78 4.901 83.483 44.11 108.152 84.689z"
            fill="rgba(248,133,100,0.1)"
            fillRule="nonzero"
        />
    </svg>
)

export default Blob
