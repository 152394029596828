import React from 'react'

const Blib = () => (
    <svg width="100%" height="100%" viewBox="0 0 411 349" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M380.74 40.962c34.47 36.424 38.694 103.778 16.728 151.357-21.881 47.495-69.953 75.13-122.502 104.877-52.465 29.663-109.577 61.355-161.028 49.1-51.535-12.338-97.41-68.706-110.167-130.905C-8.986 153.276 11.459 85.33 52.518 47.385 93.578 9.355 155.167 1.41 218.361.143c63.194-1.183 127.91 4.31 162.38 40.819z"
            fill="rgba(74,187,144,0.1)"
            fillRule="nonzero"
        />
    </svg>
)
export default Blib
