import React from 'react'

const Blub = () => (
    <svg width="100%" height="100%" viewBox="0 0 689 584" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M590.444 60.437c69.402 58.783 110.01 169.336 95.698 281.559-14.313 112.056-83.549 225.614-167.763 240.31-84.214 14.696-183.24-69.471-284.264-127.085-100.857-57.615-203.379-88.676-228.01-150.633-24.633-61.956 28.958-154.974 94.865-213.09C167.043 33.55 245.432 10.673 333.973 2.657c88.542-7.85 186.903-1.002 256.47 57.781z"
            fill="rgba(255,195,30,0.1)"
            fillRule="nonzero"
        />
    </svg>
)
export default Blub
